import { domReady } from '@roots/sage/client';
import axios from 'axios';
import Qs from 'qs';
import { header } from './header';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.Qs = Qs;

document.addEventListener('DOMContentLoaded', () => {
  header();
});
