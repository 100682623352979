export const header = () => {
  const hamburgerButtons = document.querySelectorAll('header .hamburger');
  const mobileNav = document.querySelector('header .nav-wrapper');
  const subNavLinks = document.querySelectorAll('header .nav-subnav');
  let scrollY = 0;

  hamburgerButtons.forEach((hamburger) => {
    hamburger.addEventListener('click', () => {
      mobileNav.classList.toggle('-translate-x-full');

      /**
       * See https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
       */
      if (! mobileNav.classList.contains('-translate-x-full')) {
        scrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.width = "100%";
        document.body.style.top = `-${scrollY}px`;
      } else {
        document.body.style.position = '';
        document.body.style.width = '';
        document.body.style.top = '';
        window.scrollTo(0, scrollY || 0);
      }
    });
  });

  subNavLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      if (link.nextElementSibling.classList.contains('hidden')) {
        link.nextElementSibling.classList.toggle('hidden');
        event.preventDefault();
        return false;
      }
    });
  });
};
